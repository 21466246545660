import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { LocalBusinessJsonLd } from 'gatsby-plugin-next-seo';

import Header from "../components/header"
import Story from "../components/story"
import Uryslygi from "../components/uryslygi"
import Sdelka  from "../components/sdelka"
import Price from "../components/price"
import Catalog from "../components/catalog"

import OtzivAudio from "../components/otzivAudio"
import OtzivVideo from "../components/otzivVideo"
import OtzivSocial from "../components/otzivSocial"
import OtzivBlank from "../components/otzivBlank"
import OtzivMap from "../components/otzivMap"
import Social from "../components/social"
import Certificate from "../components/certificate"
import Users from "../components/users"

import Blog from "../components/blog"
import Text from "../components/text"
import Map from "../components/map"

import ContactForm from "../components/contactForm"
import Call from "../components/call"
import Sale from "../components/sale"
import CallForm from "../components/callForm"
import Stado from "../components/stado"
import Policy from "../components/policy"

export const query = graphql `
	query  {
		blogs: allBlog(sort: { order: DESC, fields: datePublished }, limit: 10) {
			edges {
			  node {
				slug
				title
				description
				card
				img
				imgMini
				date
				seoTitle
				seoDescription
				author
				ava
				time
			  }
			} 
		}
	}
`

const IndexPage = ({data}) => {
	return (
		<Layout>
			<Seo title="Представительство в судах | Юридическая компания | Алмаз" description="! ⚖️ «Алмаз» ➥ ⭐ Урегулированные разногласия между работодателем и сотрудником по вопросам применения законов и иных нормативных правовых актов" />

			<LocalBusinessJsonLd
				type='Store'
				id='https://юридические-услуги-казань.рф/trudovye-spory'
				name="Представительство в судах | Юридическая компания | Алмаз"
				description="! ⚖️ «Алмаз» ➥ ⭐ Урегулированные разногласия между работодателем и сотрудником по вопросам применения законов и иных нормативных правовых актов"
				url='https://юридические-услуги-казань.рф'
				telephone='+78005519518'
				address={{
					streetAddress: 'Щапова, 26',
					addressLocality: 'Казань',
					addressRegion: 'Татарстан',
					postalCode: '420012',
					addressCountry: 'RU',
				}}
				geo={{
					latitude: '55.791461',
					longitude: '49.136606',
				}}
				images={[
					'https://юридические-услуги-казань.рф/img/logo.png'
				]}
			/>

			<Header title="Представительство в судах" card="Сомневаетесь, что хороший юрист Вам по карману? Не знаете, как подготовиться к судебному процессу и защитить свои права? Запутались в законах и не знаете, как грамотно сформулировать претензию ?" />
			<Story />
			<Uryslygi />
			<Sdelka />
			<Price />
			<Catalog />
			<OtzivAudio />
			<OtzivVideo />
			<OtzivSocial />
			<OtzivBlank />
			<OtzivMap />
			
			<Social />
			<Certificate />
			<Users />

			<Blog data={data.blogs} />

			<Text 
				title={"Представительство в судах"}
			
				html={

				`

				<p class="TextText">
					<b>Разрешение трудовых споров</b>
				</p>

				<p class="TextText">
					Трудовые  споры – это не урегулированные разногласия между работодателем и сотрудником по вопросам применения законов и иных нормативных правовых актов, содержащих нормы трудового права, коллективного договора, соглашения, трудового договора.
				</p>

				<p class="TextText">
					Действующее законодательство, в частности Трудовой кодекс РФ предусматривает два вида трудовых споров: коллективные и индивидуальные. Как правило,  юридические услуги чаще всего требуются для разрешения индивидуальных трудовых споров.	
				</p>

				<p class="TextText">
				Индивидуальными трудовыми спорами являются споры между работником и работодателем, между работодателем и бывшим работником, а также лицом, изъявившим желание заключить трудовой договор с работодателем (в случае отказа работодателя от заключения такого договора).
				</p>

				<p class="TextText">
				Коллективные трудовые споры возникают между работниками и работодателем по поводу условий труда. Для коллективных трудовых споров законом предусмотрен более сложный порядок урегулирования, предполагающий содействие государственных органов.
				</p>

				<p class="TextText">
				Прежде чем обратиться в орган по рассмотрению трудового спора, будь то комиссия по трудовым спорам или суд, мы рекомендуем сторонам принять меры к урегулированию возникших разногласий путем переговоров. Для успешного разрешения трудовых споров и эффективной защиты своих прав лучше всего обратиться к квалифицированному юристу. Специалисты нашей компании помогут Вам выработать наиболее оптимальную правовую позицию по Вашему вопросу, сделают правовой анализ трудового договора, иных документов, помогут составить претензию (заявление) работодателю либо письмо работнику. Также мы можем представлять Ваши интересы в переговорах с противоположной стороной с целью найти наиболее подходящее для обеих сторон компромиссное решение сложившегося трудового конфликта.
				</p>

				<p class="TextText">
				Если  же по тем или иным причинам урегулировать в досудебном порядке возникший трудовой спор не представляется возможным,  то наши юристы готовы оказать Вам помощь в составлении искового заявления и защищать Ваши интересы в суде.

				</p>
				

				<p class="TextText">
				<b>Мы оказываем широкий спектр услуг в области трудового права:</b>
				</p>
				
				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					консультации по трудовому праву
				</p>


				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					правовой анализ трудового договора, иных документов работника
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					консультации по вопросу решения трудового спора
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					разработка документов по трудовым отношениям (трудовых договоров, должностных инструкций, приказов, и т.п.)
				</p>

				`

			}/>

			<Map />

			<ContactForm />
			<Sale />
			<Call />
			<CallForm />
			<Stado />
			<Policy />
		</Layout>
	)
}


export default IndexPage
